import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query autQuery {
      img0: file(relativePath: { eq: "autism_banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0cn: file(relativePath: { eq: "autism_banner_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0en: file(relativePath: { eq: "autism_banner_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "syndromes_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.Autism" description="seo.desc.Autism" />
      {locale === "zh-hk" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0.childImageSharp.fluid}
        />
      )}
      {locale === "zh-cn" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0cn.childImageSharp.fluid}
        />
      )}
      {locale === "en" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0en.childImageSharp.fluid}
        />
      )}
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.autism.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="pb-3">
              <div className="text-dark">
                <div className="pb-3">{tt("pages.autism.desc1")}</div>
                <div className="pb-3">{tt("pages.autism.desc2")}</div>
                <div className="pb-3">
                  <ul>
                    <li>{tt("pages.autism.list1")}</li>
                    <li>{tt("pages.autism.list2")}</li>
                    <li>{tt("pages.autism.list3")}</li>
                    <li>{tt("pages.autism.list4")}</li>
                    <li>{tt("pages.autism.list5")}</li>
                    <li>{tt("pages.autism.list6")}</li>
                    <li>{tt("pages.autism.list7")}</li>
                  </ul>
                </div>
	        { locale !== "en" && (<div className="title-color pb-3">{tt("therapy")}</div>)}
                <div className="pb-3">
                  <Link to="/nsp">NSP </Link>
                  {tt("pages.autism.handling")}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Img
                className="rounded mx-auto d-block pb-3"
                fluid={data.img1.childImageSharp.fluid}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
